

export const getColorByNumber = (num) => {

  if(typeof num == 'number'){
    num = parseInt(num);
  }

  if(!num){
    return useNuxtApp().$constants.colorsRandom[0];
  }

  var last3 = num.toString().slice(-2);

  last3 = parseInt(last3);

  var res = useNuxtApp().$constants.colorsRandom[last3]; 
  // if(!res){
  //   res = useNuxtApp().$constants.colorsRandom[0];
  // }

  return res;
  


  // return useNuxtApp().$constants.colors[num % useNuxtApp().$constants.colors.length];
}