<template>

    <div class="parameters_item">

      <div 
        :class="'setup_ico setup_ico_' + ico"
        @click="openWikiFull"
        ></div>

      <div class="toggle_box">
        <div class="statistic_box active">
          <div class="tlabel" v-if="tophint">
            {{ tophint }}
          </div>
          <div class="value">            
            {{ props.beforeValue }}    
            {{ vl }}    
            {{ props.afterValue }}    
            
            <div v-if="unit" class="measure_toggle">
              <template
                v-for="un in units"
                >     
                <!-- {{ useAuth().user.value.temperature }} -->
                <!-- <div>Debug: un.id = {{ un.id }}, measure = {{ measure }}</div> -->
                <div  
                  :class="{'item': true, 'active': isActive(un.id)}"
                  @click="$emit('change_measure', un.id)"
                  v-html="un.name ? $t(un.name) : ''"
                  >
                  </div>
              </template>              
            </div>
                  
          </div>
          <div 
            class="label no_label"
            @click="openWikiFull"
            >
            {{ hint }} 
          </div>
        </div>
      </div>

    </div>
  
  
</template>

<script setup>
 
const {$convert, $constants} = useNuxtApp()

const props = defineProps({
  id: {
    type: String,
    default: null
  },
  measure: {
    type: String,
    default: null
  },
  tophint: {
    type: String,
    default: null
  },
  hint: {
    type: String,
    default: null
  },
  value: {
    type: Number,
    default: null
  },
  beforeValue: {
    type: String,
    default: null
  },
  afterValue: {
    type: String,
    default: null
  },
  unit: {
    type: String,
    default: null
  },
  ico: {
    type: String,
    default: null
  },
  wiki: {
    type: Boolean,
    default: true
  },
});

const isActive = (unitId) => {
  const active = unitId == props.measure || units.value.length < 2;
  // console.log(`unitId: ${unitId}, measure: ${props.measure}, active: ${active}`);
  return active;
};
const units = computed(() => $constants.units[props.unit]);
const vl = computed(() => $convert.view(props.unit, props.measure, props.value));



const openWikiFull = () => {
  if(props.wiki)
    useWikiModalFull().open('condition', props.id); 
}


 
</script>

<style scoped>
 
 
.setup_ico{
  display: inline-block;
  width: 65px;
  height: 65px;
  background-size: cover;
  background-repeat: no-repeat;
}

.setup_ico.setup_ico_week{ background-image: url(/images/setup/setup_icons-04.svg); }
.setup_ico.setup_ico_height{ background-image: url(/images/setup/setup_icons-06.svg); }
.setup_ico.setup_ico_light{ background-image: url(/images/setup/setup_icons-05.svg); }
.setup_ico.setup_ico_air_temp{ background-image: url(/images/setup/setup_icons-03.svg); }
.setup_ico.setup_ico_ph{ background-image: url(/images/setup/setup_icons-02.svg); }
.setup_ico.setup_ico_ppm{ background-image: url(/images/setup/setup_icons-13.svg); }
.setup_ico.setup_ico_air_hum{ background-image: url(/images/setup/setup_icons-08.svg); }
.setup_ico.setup_ico_smell{ background-image: url(/images/setup/setup_icons-01.svg); }
.setup_ico.setup_ico_solution_temp{ background-image: url(/images/setup/setup_icons-14.svg); }
.setup_ico.setup_ico_substrate_temp{ background-image: url(/images/setup/setup_icons-07.svg); }
.setup_ico.setup_ico_night_air_temp{ background-image: url(/images/setup/setup_icons-09.svg); }
.setup_ico.setup_ico_pot_size{ background-image: url(/images/setup/setup_icons-10.svg); }
.setup_ico.setup_ico_lamp_distance{ background-image: url(/images/setup/setup_icons-11.svg); }
.setup_ico.setup_ico_co2{ background-image: url(/images/setup/setup_icons-12.svg); }
.setup_ico.setup_ico_watering_volume{ background-image: url(/images/setup/setup_icons-15.svg); }



.setup_ico.setup_ico_week{ background-image: url(/images/setup/setup_icons-04.svg); }
.setup_ico.setup_ico_n_weight{ background-image: url(/images/setup/setup_icons-16.svg); }
.setup_ico.setup_ico_n_wet_weight{ background-image: url(/images/setup/setup_icons-17.svg); }
.setup_ico.setup_ico_n_plant{ background-image: url(/images/setup/setup_icons-18.svg); }
.setup_ico.setup_ico_n_watt{ background-image: url(/images/setup/setup_icons-19.svg); }
.setup_ico.setup_ico_n_space{ background-image: url(/images/setup/setup_icons-20.svg); }



.parameters .parameters_item{
width: 100%;
  max-width: 33%;
vertical-align: top;
margin: 10px 0px 15px 0px;
position: relative;
display: flex;
display: -webkit-flex;
justify-content: center;
-webkit-justify-content: center;
align-items: center;
-webkit-align-items: center;
}


.parameters .parameters_item .toggle_box{
text-align: left;
margin-top: 10px;
display: inline-block;
vertical-align: inherit;
width: calc(100% - 90px);
margin-left: 10px;
}


.parameters .parameters_item .statistic_box{
  text-align: left;
  display: none;
}

.parameters .parameters_item .statistic_box.active{
display: block;
}
.parameters .parameters_item .statistic_box .value{
font-size: 1.5rem!important;
white-space: nowrap;
}
.parameters .parameters_item .statistic_box .label{
font-weight: bold;
text-transform: uppercase;
}
.parameters .parameters_item .statistic_box .tlabel{
font-weight: bold;
text-transform: uppercase;
}


/* MEASURE TOGGLE */

.measure_toggle{
display: inline-block;
white-space: nowrap;
vertical-align: text-bottom;
z-index: 1;
position: relative;
}
.measure_toggle .item{
  display: inline-block!important;
  font-size: 0.8rem;
  text-transform: none;
  text-transform: initial;
  padding: 3px 8px;
  border: 1px var(--un-element-color-gray) solid;
  color: var(--un-text-color-gray-light);
  line-height: normal; 
  min-width: 28px;
  text-align: center;
}
.measure_toggle .item:first-child{
border-radius: 3px 0px 0px 3px;
}
.measure_toggle .item:last-child{
border-radius: 0px 3px 3px 0px;
}
.measure_toggle .item:not(.active){
cursor: pointer;
}

.measure_toggle .item.active{
border: 1px var(--un-element-color) solid;
color: var(--un-text-color);
}




  
  @container pb (max-width: 600px){
    
  .parameters{
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: left;
  justify-content: left;
  -webkit-justify-content: left;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  }

  .parameters .parameters_item{
  width: 100%;
  vertical-align: top;
  margin: 5px 0px 5px 0px;
  position: relative;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
    max-width: inherit;
  }

    .parameters .parameters_item .setup_ico{
          display: inline-block;
    width: 37px;
    height: 37px;
    background-size: cover;
    background-repeat: no-repeat;
    }

  .parameters .parameters_item .toggle_box{
    text-align: left;
    margin-top: 10px;
    display: inline-block;
    vertical-align: inherit;
    width: calc(100% - 50px);
    margin-left: 10px;
  }


  .parameters .parameters_item .statistic_box{
    text-align: left;
    display: none;
    /* display: flex!important; */
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .parameters .parameters_item .statistic_box.active{
    display: flex;
  }
  .parameters .parameters_item .statistic_box .value{
    font-size: 1rem!important;
    /* white-space: nowrap; */
    flex-wrap: wrap;
    font-weight: bold;
    width: 42%;
    /* margin-left: 8%; */
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .parameters .parameters_item .statistic_box .label{

  text-transform: inherit;
  width: 50%;
  }


    .parameters .parameters_item .statistic_box .tlabel{
      display:none;
    }


  /* MEASURE TOGGLE */

  .measure_toggle{
  display: inline-block;
  white-space: nowrap;
  vertical-align: text-bottom;
  /* margin-left: 10px; */
  }
  .measure_toggle .item{
  display: inline-block!important;
  font-size: 0.8rem;
  text-transform: initial;
  padding: 1px 5px;

  line-height: normal;
  /* vertical-align: bottom; */
  /* min-width: 38px; */
  text-align: center;
  }
  .measure_toggle .item:first-child{
  border-radius: 3px 0px 0px 3px;
  }
  .measure_toggle .item:last-child{
  border-radius: 0px 3px 3px 0px;
  }
  .measure_toggle .item:not(.active){
  cursor: pointer;
  }
 



  }



</style>
