import { ref } from "vue";

const activeWikiModalFull = ref(false)
const dataWikiModalFull = ref({})

export const useWikiModalFull = () => {

  const set = ( type, id) => {    
    dataWikiModalFull.value = { type, id }
  }

  const close = () => {   
    activeWikiModalFull.value = false
    dataWikiModalFull.value = {}
  } 

  const open = (type, id) => {    
    activeWikiModalFull.value = true
    dataWikiModalFull.value = { type, id }
  }

  return { active: activeWikiModalFull, data: dataWikiModalFull, set, open, close };
}

