<template>
  <div class="row_nutrient">
    <!-- <div class="row_nutrient__title">{{ $t('reviews_' + props.brand.category) }}</div> -->


      <div class="logo">
        <NuxtLink :to="brand.link">
          <img :src="brand.logo_s" class="logo_img">
        </NuxtLink>
      </div>

      <div class="name">
        <NuxtLink :to="brand.link">{{ brand.name }}</NuxtLink>
      </div>

      <div class="comment">
        <div
          v-if="review.text"
          class="text"
          v-html="review.text"
          ref="refNutrientText"
        />

        <GeneralTranslateButton
          v-if="review.text && refNutrientText"
          :el="refNutrientText"
          :text="review.text"
          :id="review.ref_id"
          :type="props.type"
          :locale="$i18n.locale"
        />

        <div v-if="items" class="nuts_items">
          <div
            v-for="(review_c, review_c_key) in items"
            :key="review_c_key"
            class="nuts_item"
          >
            <div class="photo">
              <NuxtLink :to="review_c.item_product.link">
                <img :src="review_c.item_product.cover_s" class="photo_img">
              </NuxtLink>
            </div>

            <div class="info">
              <NuxtLink :to="review_c.item_product.link">{{ review_c.item_product.name }}</NuxtLink>

              <div>
                <UiRating
                  :cnt="10"
                  :vl="review_c.n_general"
                  :editable="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script setup>
const props = defineProps({
  brand: {
    type: Object,
    required: true
  },
  review: {
    type: Object,
    required: true
  },
  items: {
    type: Array,
    required: false
  },
  type: {
    type: String,
    required: true
  }
})

const refNutrientText = ref(null)
</script>

<style scoped>
.row_nutrient {
  margin-bottom: 1rem;
  /* border-bottom: 1px solid #cdcdcd; */
  padding-bottom: 24px;
}

.row_nutrient__title {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  font-weight: 600;
  gap: 2em;
  line-height: 1em;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center;
  text-transform: capitalize;
}

.row_nutrient .logo {
  display: inline-block;
  vertical-align: top;
  width: 90px;
  text-align: center;
}

.row_nutrient .logo .logo_img {
  max-height: 70px;
  max-width: 70px;
}

.row_nutrient .name {
  display: inline-block;
  width: 120px;
  vertical-align: top;
  margin-top: 19px;
  margin-left: 20px;
  line-height: 1.1rem;
}
.row_nutrient .name > a {
  font-weight: bold;
  color: var(--un-text-color);
  font-size: 1.1rem;
}
.row_nutrient .comment > .text{
  background-color: var(--un-background-color-gray);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.row_nutrient .comment > p:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--un-background-color-gray) transparent transparent;
  position: absolute;
  left: -10px;
  top: 21px;
}
.row_nutrient .comment .btn_translate{
  margin-top:-10px;
  margin-bottom:10px;
  display:block;
}
.row_nutrient .comment{
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 260px);
  margin-left: 20px;
}
.row_nutrient .comment .text{
  white-space: pre-wrap;
}

.row_nutrient .comment .nuts_items{
  margin-top: 1rem;
}
.row_nutrient .comment .nuts_items .nuts_item{
  display: inline-block;
  width: 49%;
  margin-bottom: 9px;
}
.row_nutrient .comment .nuts_items .nuts_item .photo{
  display: inline-block;
  vertical-align: top;
  max-height: 46px;
  width: 50px;
  text-align: center;
}
.row_nutrient .comment .nuts_items .nuts_item .photo .photo_img{
  max-height: 35px;
  aspect-ratio: 1/1;
  object-fit: contain;
  border-radius: 5px;
}
.row_nutrient .comment .nuts_items .nuts_item .info{
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 50px);
}
.row_nutrient .comment .nuts_items .nuts_item .info > a{
  color: var(--un-text-color);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@container pb (max-width: 600px) {
  .row_nutrient{
    display: grid;
    grid-template-columns: 43px 1fr;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-template-areas:
    ". ."
    "d1 d1";
  }
  .row_nutrient .logo img{
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .row_nutrient .name{

    margin-top: 5px;
    margin-left: 0;
    width: 100%;
  }
  .row_nutrient .nuts_items{
    display:flex;
    align-items: flex-start;
    justify-self: flex-start;
    flex-wrap: wrap;
  }
  .row_nutrient .logo{
    width: 30px;
    margin: 0 auto;
  }

  .row_nutrient .comment{
    grid-area: d1;
    width: calc(100%);
    margin-left: 0;
  }
  .row_nutrient .comment .nuts_items .nuts_item{
    width: fit-content;
  }
}
</style>